import React, { useEffect } from "react"
import { navigate } from "gatsby"

const NotFoundPage = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      return navigate("/de/")
    }
  }, [])
  return <h1>404</h1>
}

export default NotFoundPage
